// config.js

const bizIcon = 'https://abelcontractor.com/wp-content/uploads/2022/09/Slider-logo.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://media.istockphoto.com/id/153710167/photo/six-workers-washing-windows.jpg?s=612x612&w=0&k=20&c=SxptZpo5ND9aEGLhfPaXFufLB3V0zTffd_VAoR59U1w=";
const imageTwo = "https://abelcontractor.com/wp-content/uploads/2022/09/S1.jpg";
const imageThree = "https://abelcontractor.com/wp-content/uploads/2022/09/S2.png";
const imageFour = "https://abelcontractor.com/wp-content/uploads/2022/09/S3.jpg";
const imageFive = "https://abelcontractor.com/wp-content/uploads/2022/09/descarga-4.png";

const redirectLink = "https://abelcontractor.com/";

export const siteConfig = {
  title: "Abel Contractor, Inc.",
  metaDescription: "Abel Contractor, Inc.",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-600"
  },
  logo: {
    src: bizIcon,
    alt: "Abel Contractor Icon",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Abel Contractor, Inc.",
    headline: "Your Trusted Contractor in DC, MD, and VA Area",
    address: "Silver Spring, MD 20902, United States",
    description: `
        Abel Contractor Inc. is your reliable partner for professional construction and remodeling services in the DC, Maryland, and Virginia areas. With years of experience in residential and commercial projects, we specialize in transforming spaces with quality craftsmanship and attention to detail. From custom renovations to new builds, our commitment to excellence ensures results that exceed expectations. Let us bring your vision to life with precision and professionalism.`,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Contractor in DC, MD, and VA Area",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Residential and Commercial Services: Offering professional construction and remodeling solutions tailored to meet the needs of homeowners and businesses in the DC, MD, and VA areas.",
    },
    {
        description: 
          "25 Years of Experience: Backed by decades of expertise, Abel Contractor Inc. has built a reputation for quality craftsmanship and reliable service.",
    },
    {
        description: 
          "Bilingual Team: Providing seamless communication and exceptional service in both English and Spanish to better serve our diverse clientele.",
    },
  ],
  services: {
    sectionTitle: "Our Services",
    description: 
    "Abel Contractor Inc proudly serves the DC, MD, and VA areas with expert contracting services tailored to residential and commercial needs. Whether you're looking for pressure washing, siding installation, or professional window cleaning, we deliver exceptional results with a focus on quality, safety, and customer satisfaction.",
    callouts: [
      {
        name: 'Window Cleaning',
        description: 
        "Our expert team uses the safest and most effective products for professional window cleaning, ensuring spotless, streak-free results. With safety as a priority, we handle even the most challenging projects efficiently and securely.",
        imageSrc: imageTwo,
        imageAlt: 'Professional window cleaning services by Abel Contractor Inc.',
      },
      {
        name: 'Pressure Washing',
        description: 
        "Abel Contractor Inc specializes in power washing services to remove dirt, algae, and contaminants from outdoor surfaces. Our meticulous approach ensures your property looks pristine and well-maintained.",
        imageSrc: imageThree,
        imageAlt: 'Pressure washing in action by Abel Contractor Inc.',
      },
      {
        name: 'Siding Installation & Repair',
        description: 
        "We offer comprehensive siding installation and repair services, working with materials like vinyl, wood, stucco, and fiber cement. Our team helps you choose the best siding for your property, taking into account durability, weather, and aesthetics.",
        imageSrc: imageFour,
        imageAlt: 'Siding installation and repair by Abel Contractor Inc.',
      },
  ],
  otherServices: [
    "Roofing Services",
    "Deck Construction & Repair",
    "Fence Installation & Repair",
    "Driveway Cleaning & Maintenance",
    "Flooring Installation & Refinishing",
  ]
  },
  about: {
    sectionTitle: "About",
    description:   
      "Abel Contractor Inc brings over 25 years of expertise in delivering exceptional residential and commercial contracting services. Based in Silver Spring, MD, we take pride in providing high-quality workmanship that meets and exceeds the expectations of our clients. Our long-standing commitment to excellence, combined with our vast experience, makes us the trusted choice for communities in DC, MD, and VA. Whether it’s a small repair or a large-scale project, you can rely on us to get the job done right.",
    image: {
      src: imageFive,
      alt: "Abel Contractor Inc team delivering high-quality service in the DC, MD, and VA area.",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Silver Spring, MD 20902",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3096.645227321497!2d-77.03056478465466!3d39.04095487955102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c9af4f238b4f%3A0xabcdef123456789!2sSilver%20Spring%2C%20MD%2020902%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=Silver%20Spring,%20MD,%2020902&ll=39.040955,-77.030565&q=Silver%20Spring',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=Silver+Spring,+MD+20902&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/Silver+Spring,+MD+20902/@39.040955,-77.030565,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/Silver+Spring,+MD+20902/@39.040955,-77.030565,15z',
      }
    ]
  },
  contact: {
    sectionTitle: "Get in Touch",
    description: "For any estimates or inquiries, feel free to contact Abel Contractor Inc directly. We're happy to assist with all your residential and commercial contracting needs in DC, MD, and VA.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (240) 876-2371",
    ],
    logo: bizIcon,
    emailRecipient: "abelcontractor@yahoo.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


